import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

// Context
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";

// components
import { ThreeTwoGallery } from "../components/images/three-two-gallery";

const Page = styled.div`
  & .heading {
    margin: 0 0 10px 0;
  }

  & a {
    &:hover {
      font-family: "RotationLTStd-Italic";
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};

  &:last-of-type {
    & .content-container {
      border-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;

    border-bottom: 1px solid #555;

    padding: 0 0 25px 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

const GridCol = styled.div`
  width: 100%;
  padding: 40px 40px 0 40px;

  &:first-of-type {
    border-right: 1px solid #555;

    @media (max-width: 768px) {
      border: 0;
    }
  }

  & .text-container {
    max-width: 550px;
    margin: 0 auto;
  }

  & .center-text {
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 30px 10px 0 10px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  border-bottom: 1px solid #555;

  padding: 0 0 40px 0;

  & p:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    border: 0;

    padding: 0;
  }
`;

const OurApproachContent = ({ data, setPageColor, setPageType }) => {
  useEffect(() => {
    setPageColor(`Pink`);
    setPageType("practice");

    document.body.style.overflow = "";
  }, []);

  const content = data.prismicOurApproach.data.body.map((content, index) => {
    const filteredImages = content.items
      .filter(image => image.images !== undefined)
      .filter(
        image => image.images.fluid !== null && image.images.fluid !== undefined
      );

    return (
      <Grid desktopCol={`1fr 1fr`} key={`oure_approach_content_${index}`}>
        <GridCol>
          <ContentContainer className="content-container">
            {filteredImages.length >= 1 && (
              <ThreeTwoGallery data={filteredImages} />
            )}
          </ContentContainer>
        </GridCol>

        <GridCol>
          <ContentContainer className="content-container">
            <div className="text-container">
              <div
                className="nhg-title"
                dangerouslySetInnerHTML={{
                  __html: content.primary.approach_title.html,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: content.primary.approach_text.html,
                }}
              />
            </div>
          </ContentContainer>
        </GridCol>
      </Grid>
    );
  });

  return (
    <Page>
      <Helmet title="Approach – PUP ARCHITECTS" />
      {content}
    </Page>
  );
};

const OurApproach = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PageColorConsumer>
        {({ setPageColor }) => (
          <OurApproachContent
            setPageColor={setPageColor}
            setPageType={setPageType}
            data={data}
          />
        )}
      </PageColorConsumer>
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    prismicOurApproach {
      data {
        body {
          ... on PrismicOurApproachBodyApproach {
            id
            primary {
              approach_title {
                html
              }
              approach_text {
                html
              }
            }
            slice_type
            items {
              images {
                alt
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPreview(OurApproach);
